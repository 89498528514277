import { motion } from "framer-motion";
// import { styles } from "../style";
import { staggerContainer } from "../utils/motion";

const SectionWrapper = (Compennet, idName) => function HOC() {
  return (
    <motion.section
      variants={staggerContainer()}
      initial='hidden'
      whileInView='show'
      viewport={{ once: true, amount: 0.25 }}
     >
      <span className='hash-span' id={idName}>
        &nbsp;
      </span>

      <Compennet />
    </motion.section>
  );
};

export default SectionWrapper;
