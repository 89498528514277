import React from "react";
 import { Box } from "@chakra-ui/react";
import Navbar from "./Navbar";
 function AppLayout({ children }) {
  return (
    <>
      <Navbar />
       <Box height="calc(100vh - 70px)" padding={{ base: 3, md: 10, }}>
        {children}
      </Box>
    </>
  );
}

export default AppLayout;
