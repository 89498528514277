import { Flex, Image } from '@chakra-ui/react'
import React from 'react'
import errorPage from "../assets/error-page.png"
function NotFound() {
  return (
    <Flex height={'100%'} justifyContent="center" alignContent="center">
      <Image src={errorPage} alt="error page"/>
      
    </Flex>
  )
}

export default NotFound
