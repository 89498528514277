import { Box } from '@chakra-ui/react';
import React from 'react'
import { useTranslation } from 'react-i18next';
import ChatBot from 'react-simple-chatbot';
import classes from '../../Styles/chat.module.css'

export const TestComp = () => {

  return (
    <div>
    <div style={{width : "256px"}}>
      <img style={{width : "100%"}} src="https://wotnot-conversation-attachment.storage.googleapis.com/111835_48367_YzFccrmkqCLm121630555156jelu8o4I_attachment.png" alt="example"/>
    </div>

  </div>
    )
}

// const steps = [
//     {
//         id: '1',
//         message: 'What can i do to reveal your beauty?',
//         trigger: '2',
//       },
//       {
//         id: '2',
//         options: [
//           { value: 1, label: 'Book An Appointment', trigger: '3' },
//         ],
//       },
//       {
//         id: '3',
//         message: 'Please select the department',
//         trigger: '4',
//       },
//       {
//         id: '4',
//         options : [
//           {value : 'laser' , label : "Laser" , trigger : '5'},
//         ],
//       },
//       {
//         id : '5',
//         component: (
//            <TestComp />
//         ),
//         trigger : '6'
//       },
//       {
//         id : '6',
//         options : [
//           {value : 'ticket' , label : "Book Me A Ticket" , trigger : '7'},
//         ]
//       },
//       {
//         id : '7',
//         message : "Ticket Has Been Booked , Do You Want Anything Else ?",
//         trigger : '8'
//       },
//       {
//         id : '8',
//         options : [
//           {value : "yes" , label : "Yes" , trigger : '2'},
//           {value : "no" , label : "No" , trigger : '9'}
//         ]
//       },
//       {
//         id: '9',
//         message: 'Thank You So Much ;)',
//         end: true,
//       },
// ]
const Chat = () => {
 
    const { t } = useTranslation(); // Use the t function for translation
  
    const steps = [
      {
        id: '1',
        message: t('chatbot.Hello and welcome to Niat Company'), // Translate the greeting message
        trigger: '2',
      },
      {
        id: '2',
        message: t('chatbot.Choose one of the services provided by the company'), // Translate the greeting message
        trigger: '3',
      },

      {
        id: '3',
        options: [
          {
            value: 'appointments',
            label: t('chatbot.application development'), // Translate the option label
            trigger: 'appointments',
          },
          {
            value: 'services',
            label: t('chatbot.software solutions'), // Translate the option label
            trigger: 'services',
          },
          {
            value: 'contact',
            label: t('chatbot.systems development'), // Translate the option label
            trigger: 'contact',
          },
          {
            value: 'exit',
            label: t('chatbot.exit'), // Translate the option label
            end: true, // End the conversation
          },
        ],
      },
      {
        id: 'appointments',
        message: t('chatbot.application development'), // Translate the message
        trigger: 'showAppointments',
      },
      {
        id: 'showAppointments',
        component: (
          <Box  padding={'10px'} fontSize="14px">
            <ul>
              <li>{t('chatbot.web and mobile applications')}</li>
              <li>{t('chatbot.Web design development')}</li>
              <li>{t('chatbot.developing enterprise portals')}</li>
              <li>{t('chatbot.E-commerce solutions')}</li>
              <li>{t('chatbot.Application maintenance')}</li>
            </ul>
          </Box>
        ),
        trigger: '2',
      },
      {
        id: 'services',
        message: t('chatbot.software solutions'), // Translate the message
        trigger: 'showServices',
      },
      {
        id: 'showServices',
        component: (
          <Box padding={'10px'} fontSize="14px">
            <ul>
              <li>{t('chatbot.customer management solutions')}</li>
              <li>{t('chatbot.supply management')}</li>
              <li>{t('chatbot.information systems solutions')}</li>
              <li>{t('chatbot.Quality and inventory management')}</li>
              <li>{t('chatbot.BI/KPI dashboards')}</li>
              {/* Translate more services here */}
            </ul>
          </Box>
        ),
        trigger: '2',
      },


      {
        id: 'contact',
        message: t('chatbot.systems development'), // Translate the message
        trigger: 'showContact',
      },

      {
        id: 'showContact',
        component: (
          <Box padding={'20px'} fontSize="14px">
            <ul>
              <li>{t('chatbot.High quality customized solutions')}</li>
              <li>{t('chatbot.systems development')}</li>
              <li>{t('chatbot.system migration and re-engineering')}</li>
              <li>{t('chatbot.software test')}</li>
              <li>{t('chatbot.Customizing open source systems')}</li>
              <li>{t("chatbot.Customized solutions for enterprises")}</li>
         
              {/* Translate more services here */}
            </ul>
          </Box>
        ),
        trigger: '2',
      },
    ];
  
    
  return (
    <div >
        <ChatBot steps={steps}  headerTitle={t("chatbot.Niyat Chatbot")}/>
    </div>
  )
}

export default Chat