import React, { useState } from "react";

import Chat from "../Components/Chat/Chat";
import { BsChatDots } from "react-icons/bs";
import { Box, Flex, Image } from "@chakra-ui/react";
import Info from "../Components/Info";
import { Container } from "@chakra-ui/react";
import { SimpleGrid } from "@chakra-ui/react";
import features from "../assets/features.png";
import featuresen from "../assets/featuresen.png";
import chatBotIcon from "../assets/chatBotIcon.png";
import { useTranslation } from "react-i18next";
import SectionWrapper from "../HOC/SectionWrapper";
import { slideIn } from "../utils/motion";
import { motion } from "framer-motion";

const Home = () => {
  const [showChatBox, setShowChatBox] = useState(true);
  const { i18n } = useTranslation();

  return (
    <Container maxW="container.xl" height={"100%"}>
      <SimpleGrid
        columns={{ base: 1, md: 2, lg: 2 }}
        spacing="10"
        height={"100%"}
        alignItems="center"
      >
        <motion.div variants={slideIn("left", "tween", 0.2, 0.3)}>
          <Flex
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            marginBottom="20px"
          >
            <Info />
            <Box margin={"30px"}>
              {i18n.language === "ar" ? (
                <Image src={features} alt={"features"} />
              ) : (
                <Image src={featuresen} alt={"featuresen"} />
              )}
            </Box>
          </Flex>
        </motion.div>
        <motion.div variants={slideIn("right", "tween", 0.2, 0.3)}>
          <Flex
            flexDirection="column"
            alignItems="flex-end"
            width="100%"
            justifyContent="end"
            marginBottom="20px"
          >
            {showChatBox && (
              <Box>
                <Chat />
              </Box>
            )}
            <Box
              cursor={"pointer"}
              border={"3px solid #03D4DB"}
              padding="5px"
              borderRadius={"50%"}
              bg="#fff"
              onClick={() => setShowChatBox((prev) => !prev)}
            >
              <img src={chatBotIcon} height="50" width="50" alt="chatBot" />
            </Box>
          </Flex>
        </motion.div>
      </SimpleGrid>
    </Container>
  );
};

// export default Home;
export default SectionWrapper(Home, "Home");
