import { Text } from "@chakra-ui/react";
import { Button } from "@chakra-ui/react";
import { Box } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";

function Info() {
  const { t } = useTranslation();
 
  return (
    <Box padding="20px" bg="#fff">
      <Text
        marginBottom="25px"
        fontSize="20px"
        color="#03D4DB"
        fontWeight="bold"
      >
        {t("pages.home.Who We Are ?")}
      </Text>
      <Text fontSize="18px">
      {t(`pages.home.info`)}
      </Text>
      <Button marginTop={"20px"} color="#fff" backgroundColor={"#03D4DB"}>
        {t("general.learn more")}
      </Button>
    </Box>
  );
}

export default Info;
